<template>
  <b-container fluid>

    <b-row class="justify-content-md-center auth-wrapper px-2">
      <b-col md="8">

        <!-- Login v1 -->
        <b-overlay
          :show="loader"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-lg
          rounded="sm"
        >

          <b-card class="mb-0 my-5">
            <form-wizard
              color="#7367F0"
              title="Formulaire d'inscription"
              subtitle="Mettez votre expertise à la disposition de la communauté et obtenez une rénumération à la hauteur de votre effort."
              finish-button-text="S'inscrire"
              back-button-text="Précédent"
              next-button-text="Suivant"
              button-size="sm"
              class="steps-transparent mb-3 font-size"
              justified
              @on-complete="storeUser()"
            >
              <tab-content :before-change="validationIsConditionForm">
                <validation-observer
                  ref="accountConditionRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      md="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Conditions d'utilisation
                      </h5>
                      <small class="text-muted">
                        Accepter nos conditions d'utilisation.
                      </small>
                    </b-col>
                    <b-col md="12">
                      <b-alert
                        variant="dark"
                        show
                      >
                        <h4 class="alert-heading">
                          Conditions d'utilisation
                        </h4>
                        <div class="alert-body">
                          Voici les conditions d'utilisation de la plateforme bourse de frêt:
                          <ul>
                            <li>Les conditions générales d’utilisation ne sont pas un document
                              obligatoire. Il s’agit d’un document optionnel qu’il est
                              possible de soumettre à l’utilisateur d’un service pour en
                              encadrer l’utilisation.</li>
                            <li>C'est un document contractuel. On parle de contrat d’adhésion
                              par opposition au contrat négocié. L’utilisateur du service
                              choisit de les accepter ou de les refuser mais ne négocie pas
                              leur contenu avec l’exploitant du service.</li>
                            <li>Sur internet, les conditions générales d’utilisation peuvent
                              être utilisées sur les sites marchands comme sur les sites non
                              marchands, leur objet étant d’encadrer l’utilisation du site.
                              Elles ne sont pas une obligation légale. En revanche, les
                              conditions générales de vente ne trouvent d’intérêt que sur les
                              sites marchands, leur objet étant d’encadrer une relation
                              commerciale. Elles constituent une obligation légale sur tous
                              les sites marchands.</li>
                            <li>Les CGU sont souvent accusées d'être écrites dans un langage
                              juridique peu compréhensible pour un utilisateur lambda ainsi
                              que dans une taille de police trop petite. Ces contrats sont
                              généralement très long et peu d'internautes les lisent en
                              entier. Plusieurs initiatives ont néanmoins vu le jour pour
                              clarifier les dispositions que l'utilisateur accepte</li>
                            <li>La majorité des sites internet disposent de conditions générales
                              d’utilisation. Leurs contenus détaillent le fonctionnement
                              général du site, les modalités de son utilisation et les règles
                              à respecter pour ses utilisateurs.</li>
                          </ul>
                        </div>
                      </b-alert>
                      <validation-provider
                        #default="{ errors }"
                        name="condition d'utilisation "
                        :rules="{ required: { allowFalse: false } }"
                      >
                        <!--/ collapse -->
                        <b-form-checkbox
                          v-model="form.is_condition"
                          :state="errors.length > 0 ? false : null"
                          class="custom-control-primary mt-2"
                        >
                          Lu et accepté
                        </b-form-checkbox>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

              <tab-content :before-change="validationForm">
                <validation-observer
                  ref="accountRules"
                  tag="form"
                >
                  <b-row class="justify-content-md-center">
                    <b-col
                      md="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Domaine d'expertise
                      </h5>
                      <small class="text-muted">
                        Dans quel domaine souhaitez-vous apporter votre expertise?
                      </small>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Type de compte"
                        label-for="role"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="type d'expertise"
                          rules="required"
                        >
                          <v-select
                            id="role"
                            v-model="form.expertise"
                            label="libelle"
                            :state="errors.length > 0 ? false : null"
                            :options="typeExperts"
                            placeholder="Choisir un domaine"
                            :reduce="(typeExperts) => typeExperts.id"
                            class="select-size-sm"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>
              <tab-content :before-change="validationPersonnalForm">
                <validation-observer
                  ref="accountPersonnalRules"
                  tag="form"
                >
                  <b-row>
                    <b-col
                      md="12"
                      class="mb-2"
                    >
                      <h5 class="mb-0">
                        Détails personnels
                      </h5>
                      <small class="text-muted"> Détails </small>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Nom d'utilisateur"
                        label-for="username"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="nom d'utilisateur"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.username"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom d'utilisateur"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Nom"
                        label-for="lastname"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="nom"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.lastname"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Nom"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Prénom"
                        label-for="firstname"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="prénom"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.firstname"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Prénom"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="email"
                          rules="required|email"
                        >
                          <b-form-input
                            v-model="form.email"
                            size="sm"
                            type="email"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Adresse"
                        label-for="adresse"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="adresse"
                          rules="required"
                        >
                          <b-form-input
                            v-model="form.adresse"
                            size="sm"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Adresse"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Téléphone"
                        label-for="user_phone"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="téléphone"
                          rules="required"
                          type="tel"
                        >
                          <VuePhoneNumberInput
                            v-model="form.telephonefull"
                            default-country-code="BJ"
                            show-code-on-list
                            size="sm"
                            :translations="configPhoneNumber"
                            @update="telephonefull = $event"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">

                      <b-form-group
                        label="Mot de passe"
                        label-for="a-password"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="mot de passe"
                          vid="Password"
                          rules="required|min:8|password"
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              id="a-password"
                              v-model="form.password"
                              :type="passwordFieldType"
                              class="form-control-merge"
                              size="sm"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Mot de passe"
                            />

                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon"
                                @click="togglePasswordVisibility"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group
                        label="Confirmer mot de passe"
                        label-for="ac-password"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="mot de passe"
                          rules="required|confirmed:Password"
                        >

                          <b-form-input
                            id="ac-password"
                            v-model="form.password_confirmation"
                            :state="errors.length > 0 ? false : null"
                            type="password"
                            size="sm"
                            placeholder="Confirmer mot de passe"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="float-right"
                        onclick="history.back()"
                      >
                        <feather-icon icon="ArrowLeftCircleIcon" />
                        Annuler
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </tab-content>

              <tab-content :before-change="validationFilesForm">
                <b-row class="justify-content-md-center">
                  <b-col
                    md="12"
                    class=""
                  >
                    <b-alert
                      variant="dark"
                      show
                    >
                      <h4 class="alert-heading">
                        Liste des pièces (Obligatoire):
                      </h4>
                      <div class="alert-body">
                        <span style="font-size: 12px;">Identification Fiscale Unique (IFU) </span>
                        <br>
                        <span style="font-size: 12px;">Carte Nationale d'identité (CNI)</span> <br>
                        <span style="font-size: 12px;">Document attestant de votre expertise dans
                          votre domaine</span> <br>
                      </div>
                    </b-alert>
                  </b-col>
                  <b-col
                    md="12"
                    class=""
                  >
                    <!-- modal data -->
                    <div class="mt-2">

                      <div v-if="submittedNames.length === 0">

                        <b-table
                          :fields="fields"
                          small
                          responsive="sm"
                          :items="items"
                        >
                          <template #cell(actions)="data">

                            <feather-icon
                              v-b-modal.modal-prevent-image-closing
                              icon="EyeIcon"
                              @click="getImage(data)"
                            />

                            <feather-icon
                              class="text-danger ml-2  w"
                              icon="TrashIcon"
                              @click="removeItem(data)"
                            />
                          </template>
                        </b-table>

                      </div>
                      <b-list-group v-else>
                        <b-list-group-item
                          v-for="(data, index) in submittedNames"
                          :key="index"
                        >
                          {{ data }}
                        </b-list-group-item>
                      </b-list-group>

                    </div>
                  </b-col>

                  <b-col
                    class="text-center"
                    md="6"
                  >
                    <!-- button -->
                    <b-button
                      id="toggle-btn"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-modal.modal-prevent-closing
                      variant="outline-primary"
                      size="sm"
                    >
                      Ajouter une pièce
                    </b-button>

                    <div>

                      <b-modal
                        id="modal-prevent-image-closing"
                        ref="my-modal"
                        button-size="sm"
                        title="Pièce jointe"
                        ok-only
                        ok-title="Fermer"
                      >
                        <iframe
                          :src="imageUrl"
                          style="width: 100%; height: 600px; border: none;"
                        >
                          Oops! an error has occurred.
                        </iframe>

                      </b-modal>

                      <!-- modal -->
                      <b-modal
                        id="modal-prevent-closing"
                        ref="my-modal"
                        no-close-on-backdrop
                        button-size="sm"
                        title="Ajouter une pièce jointe"
                        :busy="documentProcess"
                        ok-title="Ajouter"
                        cancel-title="Fermer"
                        cancel-variant="secondary"
                        ok-variant="success"
                        @show="clearPiece"
                        @ok="handleOk"
                      >
                        <validation-observer ref="accountFilesRules">
                          <form @submit.prevent="handleSubmitModal">

                            <b-form-group
                              label="Type de pièce"
                              label-for="piece"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="type de pièce"
                                rules="required"
                              >
                                <v-select
                                  id="piece"
                                  v-model="piece.type_document"
                                  label="name"
                                  size="sm"
                                  :state="errors.length > 0 ? false : null"
                                  :options="documents"
                                  placeholder="Choisir une piece"
                                  :reduce="(documents) => documents.slug"
                                  class="select-size-sm"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>

                            <b-form-group
                              label="Date d'expiration"
                              label-for="date_expiration"
                              label-size="sm"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="date d'expiration"
                                rules="required"
                              >
                                <flat-pickr
                                  id="date_expiration"
                                  v-model="piece.date_expiration"
                                  class="form-control"
                                  size="sm"
                                  :config="config"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>

                              </validation-provider>
                            </b-form-group>
                            <b-form-group
                              label="Numéro de la pièce"
                              label-for="piece_number"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="numéro de pièce"
                                rules="required"
                              >
                                <b-form-input
                                  id="piece_number"
                                  v-model="piece.numero_de_la_piece"
                                  size="sm"
                                  :state="errors.length > 0 ? false : null"
                                  placeholder="Numéro de la pièce"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>

                            <b-form-group
                              label="Pièce justificative"
                              label-for="fichier"
                              label-size="sm"
                            >
                              <validation-provider
                                #default="{ errors }"
                                name="pièce justificative"
                                rules="required"
                              >
                                <b-form-file
                                  id="fichier"
                                  ref="file-input"
                                  v-model="piece.fichier"
                                  accept="pdf"
                                  size="sm"
                                  placeholder="Choisir un fichier"
                                  @change="handleImageSelected"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>

                          </form>
                        </validation-observer>
                      </b-modal>
                    </div>

                  </b-col>
                  <b-col md="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="float-right"
                      onclick="history.back()"
                    >
                      <feather-icon icon="ArrowLeftCircleIcon" />
                      Annuler
                    </b-button>
                  </b-col>

                </b-row>
              </tab-content>
            </form-wizard>

          </b-card>

        </b-overlay>

        <!-- /Login v1 -->
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'

import {
  ref, reactive, watch, onMounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, password, image, ext, mimes,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useExpertRegister from '@/services/authentification/registerExpertService'
// eslint-disable-next-line import/no-cycle
import useImageUpload from '@/services/upload/useImageUpload'
import VuePhoneNumberInput from 'vue-phone-number-input'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import { French } from 'flatpickr/dist/l10n/fr'
// eslint-disable-next-line import/no-cycle
import useTypeExperts from '@/services/expertise/typeExpertService'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BContainer,
  BInputGroup,
  BOverlay,

  BInputGroupAppend,
  BCard,
  BFormCheckbox,
  BFormFile,
  BButton, BModal, VBModal, BListGroup, BListGroupItem, BTable, BAlert,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import useDocuments from '@/services/admin/documentService'

export default {
  components: {
    BTable,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BInputGroupAppend,
    BInputGroup,
    BOverlay,

    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BContainer,
    BAlert,
    BFormCheckbox,
    BFormFile,
    flatPickr,

    vSelect,
    BButton,
    BModal,
    BListGroup,
    BListGroupItem,
    VuePhoneNumberInput,
    // eslint-disable-next-line vue/no-unused-components

  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],

  data() {
    return {
      required,
      password,
      email,
      confirmed,
      image,
      ext,
      mimes,
    }
  },
  setup() {
    const { handleRegister, loader } = useExpertRegister()
    const {
      getDocuments, documents, getDocument, document, documentProcess,
    } = useDocuments()
    const {
      imageUrl, imageFile, handleImageSelected, uploadFile, fileName, deleteFile,
    } = useImageUpload()
    const {
      getTypeExperts, typeExperts,
    } = useTypeExperts()
    onMounted(async () => {
      await getDocuments()
      console.clear()
      await getTypeExperts()
    })
    const submittedNames = []
    const fields = reactive([
      { key: 'name_document', label: 'Type de document' },
      { key: 'numero_de_la_piece', label: 'Numéro de la pièce' },
      { key: 'date_expiration', label: 'Date d\'expiration' },
      { key: 'actions' },
    ])

    const items = reactive([])
    const piece = ref({})
    const clearPiece = () => {
      piece.value = {}
      imageUrl.value = ''
      fileName.value = ''
    }
    async function queuePurchase() {
      const data = new FormData()
      data.append('fichier', imageFile.value)
      await uploadFile(data)
      await getDocument(piece.value.type_document)
      piece.value.type_document = document.value.id
      piece.value.name_document = document.value.name
      piece.value.fileName = fileName.value
      piece.value.imageUrl = imageUrl.value
      items.push(piece.value)
      clearPiece()
    }

    const roles = [
      { value: null, title: 'Choisir le domaine', disabled: true },
      { value: 'A', title: 'Expertise A' },
      { value: 'B', title: 'Expertise B' },
      {
        value: 'C',
        title: 'Expertise C',
      },
    ]

    const getImage = data => {
      console.clear()
      imageUrl.value = data.item.imageUrl
    }

    const removeItem = async data => {
      console.clear()
      await deleteFile(data.item.fileName)
      const indexOfObject = items.findIndex(object => object.fileName === data.item.fileName)
      items.splice(indexOfObject, 1)
    }

    const form = reactive({
      role: 'ROLE_EXPERT',
      expertise: '',
      is_condition: '',
      username: '',
      lastname: '',
      firstname: '',
      email: '',
      adresse: '',
      telephone: '',
      telephonefull: '',
      password: '',
      password_confirmation: '',
      selected: '',
      fichiers: items,
    })
    const telephonefull = ref('')
    // eslint-disable-next-line no-unused-vars
    watch(() => (form.telephonefull), value => {
      form.telephone = ''
      form.telephone = telephonefull.value.formattedNumber
      console.clear()
    })
    // S'Enregistrer
    const storeUser = async () => {
      await handleRegister({ ...form })
    }
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M  Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }

    return {
      roles,
      telephonefull,
      documents,
      config,
      form,
      submittedNames,
      items,
      fields,
      storeUser,
      queuePurchase,
      getImage,
      removeItem,
      piece,
      imageUrl,
      clearPiece,
      loader,
      documentProcess,
      handleImageSelected,
      typeExperts,
      getTypeExperts,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods: {
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    formatter(value) {
      return value.toLowerCase()
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationIsConditionForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountConditionRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationPersonnalForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountPersonnalRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFilesForm() {
      return new Promise((resolve, reject) => {
        if (this.form.fichiers.length >= 2) {
          resolve(true)
        } else {
          reject()
        }
      })
    },
    validationEntrepriseForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountEntrepriseRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    handleSubmitModal() {
      // Exit when the form isn't valid
      this.$refs.accountFilesRules.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          this.queuePurchase(this.piece)
          this.$refs['file-input'].reset()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal'].toggle('#toggle-btn')
          })
        }
      })
    },

  },

}
</script>

<style scoped>
.font-size {
    font-size: 9 px !important;
}

.auth-wrapper {
    background-image: url(../../../assets/images/banner1.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background-repeat: no-repeat; */
}
</style>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
